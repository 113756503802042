import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isAboutUsDropdownVisible, setAboutUsDropdownVisible] = useState(false);
  const [isExperiencesDropdownVisible, setExperiencesDropdownVisible] = useState(false);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white text-revolution-dark">
      <div className="mx-auto flex items-center justify-between gap-x-2 sm:gap-x-0 py-4 px-7" style={{height: '100px'}}>
        {/* Mobile menu button */}
        <div className="nav flex flex-1 items-center xl:hidden">
          <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="">
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6 font-bold text-dark-900" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <line x1="21" x2="3" y1="6" y2="6"></line>
              <line x1="15" x2="3" y1="12" y2="12"></line>
              <line x1="17" x2="3" y1="18" y2="18"></line>
            </svg>
          </button>
        </div>

        {/* Logo */}
        <div className="logo flex-1 flex items-center justify-center xl:justify-start">
          <Link to="/" className="brand">
            <div className="logo-container w-[180px] h-[74px] relative">
              <img 
                alt="brand logo" 
                loading="lazy" 
                decoding="async" 
                className="object-contain absolute inset-0 w-full h-full"
                sizes="300px" 
                srcSet="https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 16w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 32w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 48w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 64w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 96w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 128w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 256w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 384w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 640w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 750w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 828w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 1080w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 1200w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 1920w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 2048w, https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg 3840w"
                src="https://s3.amazonaws.com/ttand/uploads/users/logos/smills.jpg"
              />
            </div>
          </Link>
        </div>

        {/* Navigation */}
        <div className="navigation flex flex-1 xl:flex-auto items-center justify-end gap-x-4">
          {/* Mobile sidebar */}
          <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-white transform ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out xl:hidden`}>
            <nav className="h-full overflow-y-auto py-5 px-3">
              <ul className="flex flex-col space-y-2">
                <li className="relative cursor-pointer">
                  <Link to="https://www.revolutiontravel.ca/about-us" className="block py-2 hover:text-revolution-orange">
                    About Us
                  </Link>
                  <ul className="pl-4 mt-2 space-y-2">
                    <li><Link to="https://www.revolutiontravel.ca/about-us" className="block py-2 hover:text-revolution-orange">About Us</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/testimonials" className="block py-2 hover:text-revolution-orange">Testimonials</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/suppliers" className="block py-2 hover:text-revolution-orange">Our Partners</Link></li>
                  </ul>
                </li>
                <li className="relative cursor-pointer">
                  <Link to="https://www.revolutiontravel.ca/experiences" className="block py-2 hover:text-revolution-orange">
                    Experiences
                  </Link>
                  <ul className="pl-4 mt-2 space-y-2">
                    <li><Link to="https://www.revolutiontravel.ca/experience/culinary/3" className="block py-2 hover:text-revolution-orange">Culinary</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/experience/ocean-cruises/12" className="block py-2 hover:text-revolution-orange">Ocean Cruises</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/experience/lgbtq/14" className="block py-2 hover:text-revolution-orange">LGBTQ</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/experience/wellness/9" className="block py-2 hover:text-revolution-orange">Wellness</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/experience/romance/11" className="block py-2 hover:text-revolution-orange">Romance</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/experience/europe/6" className="block py-2 hover:text-revolution-orange">Europe</Link></li>
                    <li><Link to="https://www.revolutiontravel.ca/experiences" className="block py-2 hover:text-revolution-orange">View All</Link></li>
                  </ul>
                </li>
                {['Tours', 'Offers', 'Blogs', 'Contest', 'Testimonials', 'Contact', 'Book Now'].map((item) => (
                  <li key={item} className="relative cursor-pointer">
                    <Link to={`https://www.revolutiontravel.ca/${item.toLowerCase().replace(' ', '-')}`} className="block py-2 hover:text-revolution-orange">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden xl:block">
            <ul className="flex items-center gap-x-4">
              <li className="relative cursor-pointer" onMouseEnter={() => setAboutUsDropdownVisible(true)} onMouseLeave={() => setAboutUsDropdownVisible(false)}>
                <Link to="https://www.revolutiontravel.ca/about-us" className="py-6 flex items-center hover:text-revolution-orange" style={{textUnderlineOffset: '8px', textDecorationThickness: '2.5px'}}>
                  About Us
                  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="m6 9 6 6 6-6"></path>
                  </svg>
                </Link>
                {isAboutUsDropdownVisible && (
                  <div className="absolute top-14 left-0 z-40 bg-white text-dark-900 rounded w-48 text-sm" style={{boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.175)'}}>
                    <ul className="px-3 py-3">
                      <li><Link to="https://www.revolutiontravel.ca/about-us" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">About Us</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/testimonials" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Testimonials</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/suppliers" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Our Partners</Link></li>
                    </ul>
                  </div>
                )}
              </li>
              <li className="relative cursor-pointer" onMouseEnter={() => setExperiencesDropdownVisible(true)} onMouseLeave={() => setExperiencesDropdownVisible(false)}>
                <Link to="https://www.revolutiontravel.ca/experiences" className="py-6 flex items-center hover:text-revolution-orange" style={{textUnderlineOffset: '8px', textDecorationThickness: '2.5px'}}>
                  Experiences
                  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 ml-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="m6 9 6 6 6-6"></path>
                  </svg>
                </Link>
                {isExperiencesDropdownVisible && (
                  <div className="absolute top-14 left-0 z-40 bg-white text-dark-900 rounded w-48 text-sm" style={{boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.175)'}}>
                    <ul className="px-3 py-3">
                      <li><Link to="https://www.revolutiontravel.ca/experience/culinary/3" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Culinary</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/experience/ocean-cruises/12" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Ocean Cruises</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/experience/lgbtq/14" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">LGBTQ</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/experience/wellness/9" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Wellness</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/experience/romance/11" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Romance</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/experience/europe/6" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">Europe</Link></li>
                      <li><Link to="https://www.revolutiontravel.ca/experiences" className="px-3 py-3 h-full hover:white w-full block rounded hover:text-revolution-orange hover:bg-revolution-gray">View All</Link></li>
                    </ul>
                  </div>
                )}
              </li>
              {['Tours', 'Offers', 'Blogs', 'Contest', 'Testimonials', 'Contact', 'Book Now'].map((item) => (
                <li key={item} className="relative cursor-pointer">
                  <Link to={`https://www.revolutiontravel.ca/${item.toLowerCase().replace(' ', '-')}`} className="py-6 flex items-center hover:text-revolution-orange" style={{textUnderlineOffset: '8px', textDecorationThickness: '2.5px'}}>
                    {item}
                  </Link>
                </li>
              ))}
              <li>
                <a href="https://www.facebook.com/revolutiontravelgroup" target="_blank" rel="noopener noreferrer" className="hover:text-revolution-orange">
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/revolution_travel_group" target="_blank" rel="noopener noreferrer" className="hover:text-revolution-orange">
                  <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@revolutiontravelgroup" target="_blank" rel="noopener noreferrer" className="hover:text-revolution-orange">
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 8.24537V15.5C16 19.0899 13.0899 22 9.5 22C5.91015 22 3 19.0899 3 15.5C3 11.9101 5.91015 9 9.5 9C10.0163 9 10.5185 9.06019 11 9.17393V12.3368C10.5454 12.1208 10.0368 12 9.5 12C7.567 12 6 13.567 6 15.5C6 17.433 7.567 19 9.5 19C11.433 19 13 17.433 13 15.5V2H16C16 4.76142 18.2386 7 21 7V10C19.1081 10 17.3696 9.34328 16 8.24537Z"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
          <div className="search ml-4 hidden xs:block">
            <Link to="https://www.revolutiontravel.ca/search">
              <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4 hover:text-revolution-orange" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="11" r="8"></circle>
                <path d="m21 21-4.3-4.3"></path>
              </svg>
            </Link>
          </div>
          <select name="locale" id="locale" aria-label="Change Language" className="bg-transparent border-none outline-none uppercase text-sm" defaultValue="en">
            <option className="bg-white text-dark-900 uppercase" value="en">en</option>
            <option className="bg-white text-dark-900 uppercase" value="fr">fr</option>
          </select>
        </div>
      </div>
    </header>
  );
};

export default Header;