import React from 'react';

const FeatureCard = ({ title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h4 className="text-xl font-bold text-indigo-900 mb-2">{title}</h4>
    <p className="text-gray-600">{description}</p>
  </div>
);

const WhyTravel = () => {
  return (
    <section className="bg-gray-100 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Why Become a <span className="text-indigo-600">Travel Agent</span>?
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Embark on an exciting career that allows you to explore the world, help others create unforgettable memories, and enjoy a flexible work-life balance.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-8">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Join an Award-Winning Team
              </h3>
              <p className="text-gray-600 mb-6">
                As an award-winning travel agent, I can guide you to success in this exciting industry. Build valuable skills in customer service, sales, and destination knowledge while networking with top professionals worldwide.
              </p>
            </div>
            <div className="relative pb-[50%] md:pb-0">
              <img
                src="/Top100.jpeg"
                alt="Top 100 in North America Award"
                className="rounded-lg shadow-xl w-full h-auto aspect-square object-cover"
              />
              <img
                src="/VVHeadOffice.jpeg"
                alt="Accepting Travel Agent Award"
                className="absolute bottom-0 left-0 transform translate-x-1/4 translate-y-1/4 w-1/2 h-1/2 rounded-lg shadow-xl object-cover z-10"
              />
            </div>
          </div>

          <div className="space-y-8 mt-16 md:mt-0">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">
                Grow Your Business into a Rewarding Profession
              </h3>
              <p className="text-gray-600 mb-6">
                As an experienced travel agent, I'll guide you in transforming your passion for travel into a thriving business. You'll learn how to build a loyal client base, maximize your earnings, and create a sustainable career path in the dynamic travel industry.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-6">
              <FeatureCard
                title="Be Your Own Boss"
                description="With my guidance, start and grow your own travel business, set your own goals, and enjoy the freedom of entrepreneurship in the travel industry."
              />
              <FeatureCard
                title="Build Industry Connections"
                description="I'll help you develop a network of valuable contacts within the travel industry, including suppliers, business development managers, and tourism boards, enhancing your ability to provide exceptional knowledge and service to your clients."
              />
              <FeatureCard
                title="Flexible Work Environment"
                description="Learn from my experience how to effectively work from anywhere in the world, set your own hours, and achieve a better work-life balance."
              />
              <FeatureCard
                title="Exclusive Perks and Discounts"
                description="I'll show you earn your way to industry-only travel discounts, complimentary upgrades, and special rates on flights, hotels, tours, and cruises."
              />
            </div>
          </div>
        </div>

        <div className="mt-16 flex justify-center space-x-2">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="w-2 h-2 rounded-full bg-indigo-500"
            ></div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyTravel;