import React from 'react';

function Footer() {
  return (
    <footer className="bg-revolution-dark text-white py-8">
      <div className="footer-top w-full flex flex-col sm:flex-row justify-between gap-x-14 max-w-6xl px-6 xs:px-12 mx-auto">
        <div className="footer-links flex-1 order-1 sm:order-2 flex flex-col mb-8 sm:mb-0">
          <div className="links flex flex-col xs:flex-row xs:gap-x-10 xs:justify-start gap-y-6">
            <section className="useful-links">
              <h5 className="text-base mb-4 text-white whitespace-nowrap">Useful Links</h5>
              <ul className="flex flex-col text-sm gap-y-3">
                <li><a href="https://www.revolutiontravel.ca/about-us">About</a></li>
                <li><a href="https://www.revolutiontravel.ca/tours">Tours</a></li>
                <li><a href="https://www.revolutiontravel.ca/blogs">Blogs</a></li>
                <li><a href="https://www.revolutiontravel.ca/testimonials">Testimonials</a></li>
                <li><a href="https://www.revolutiontravel.ca/contact-us">Contact Us</a></li>
              </ul>
            </section>
            <section className="contact-us">
              <h5 className="text-base mb-4 text-white whitespace-nowrap">Contact Us</h5>
              <ul className="flex flex-col text-sm gap-y-3 mb-6">
                <li>
                  <span className="flex items-center gap-x-2">
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <rect width="14" height="20" x="5" y="2" rx="2" ry="2"></rect>
                      <path d="M12 18h.01"></path>
                    </svg>
                    <span className="flex-1">416-669-6505</span>
                  </span>
                </li>
                <li>
                  <span className="flex items-center gap-x-2">
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <rect width="14" height="20" x="5" y="2" rx="2" ry="2"></rect>
                      <path d="M12 18h.01"></path>
                    </svg>
                    <span className="flex-1">416-367-8264 EXT 3299</span>
                  </span>
                </li>
                <li>
                  <a className="flex items-center gap-x-2 break-all" href="mailto:sean@revolutiontravel.ca">
                    <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M22 17a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.5C2 7 4 5 6.5 5H18c2.2 0 4 1.8 4 4v8Z"></path>
                      <polyline points="15,9 18,9 18,11"></polyline>
                      <path d="M6.5 5C9 5 11 7 11 9.5V17a2 2 0 0 1-2 2v0"></path>
                      <line x1="6" x2="7" y1="10" y2="10"></line>
                    </svg>
                    <span className="flex-1">sean@revolutiontravel.ca</span>
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </div>
        <section className="company-info order-2 sm:order-1 flex-1">
          <div className="brand mb-4">
            <a href="https://www.revolutiontravel.ca/">
              <img alt="brand logo" loading="lazy" width="180" height="64" decoding="async" data-nimg="1" className="object-contain" style={{color: "transparent"}} src="https://s3.amazonaws.com/ttand/uploads/users/logos-light/smills.jpg" />
            </a>
          </div>
          <div className="timings mb-3 flex items-center gap-x-2">
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            Hours 9:00 am - 6:00 pm (ET)
          </div>
          <address className="address mb-3 flex items-start gap-x-2">
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <polygon points="3 6 9 3 15 6 21 3 21 18 15 21 9 18 3 21"></polygon>
              <line x1="9" x2="9" y1="3" y2="18"></line>
              <line x1="15" x2="15" y1="6" y2="21"></line>
            </svg>
            1003-55 York Street, Toronto, ON, M5J 1R7, Canada
          </address>
          <div className="licenses mb-3 flex items-center gap-x-2">
            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.21 15 2.66 7.14a2 2 0 0 1 .13-2.2L4.4 2.8A2 2 0 0 1 6 2h12a2 2 0 0 1 1.6.8l1.6 2.14a2 2 0 0 1 .14 2.2L16.79 15"></path>
              <path d="M11 12 5.12 2.2"></path>
              <path d="m13 12 5.88-9.8"></path>
              <path d="M8 7h8"></path>
              <circle cx="12" cy="17" r="5"></circle>
              <path d="M12 18v-2h-.5"></path>
            </svg>
            <span className="inline-block mr-2 uppercase">tico: 50021282</span>
          </div>
        </section>
      </div>
      <div className="h-0.5 w-full bg-gray-700 my-8"></div>
      <div className="footer-bottom flex flex-col sm:flex-row gap-y-3 items-center sm:justify-between w-full px-4 xs:px-6 mx-auto max-w-6xl">
        <section className="social-media flex items-center">
          <h5 className="font-medium mr-4 text-white">Follow Us</h5>
          <div className="flex items-center gap-x-2">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/revolutiontravelgroup">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" className="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
              </svg>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/revolution_travel_group">
              <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
              </svg>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@revolutiontravelgroup">
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="w-4 h-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8.24537V15.5C16 19.0899 13.0899 22 9.5 22C5.91015 22 3 19.0899 3 15.5C3 11.9101 5.91015 9 9.5 9C10.0163 9 10.5185 9.06019 11 9.17393V12.3368C10.5454 12.1208 10.0368 12 9.5 12C7.567 12 6 13.567 6 15.5C6 17.433 7.567 19 9.5 19C11.433 19 13 17.433 13 15.5V2H16C16 4.76142 18.2386 7 21 7V10C19.1081 10 17.3696 9.34328 16 8.24537Z"></path>
              </svg>
            </a>
          </div>
        </section>
        <section className="copyright text-center">
          <a href="https://www.revolutiontravel.ca/content/policy">Privacy Policy | </a>
          <span>© 2024 Revolution Travel Group</span>
        </section>
      </div>
    </footer>
  );
}

export default Footer;