import React from 'react';

const Hero = () => {
  return (
    <div className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 lg:py-32">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 lg:pr-8">
            <div className="flex items-center mb-4">
              <div className="bg-blue-500 rounded-full p-1 mr-2">
                <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
              <p className="text-blue-500 font-semibold text-sm">Explore the world as a career</p>
            </div>
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight mb-4">
              Become a <br />
              Travel Agent <br />
              and See the World
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Turn your passion for travel into a rewarding career. As a travel agent, 
              you'll help others create unforgettable experiences while exploring the globe yourself. 
              <span className="font-bold text-blue-500"> Join the exciting world of travel and tourism! </span>
            </p>
            <a
              href="#"
              className="inline-block w-full sm:w-auto px-6 py-3 bg-blue-500 text-white font-bold rounded-lg text-center hover:bg-blue-600 transition duration-300"
            >
              Start Your Journey
            </a>
            <div className="mt-6 flex items-center">
              <div className="flex -space-x-2 overflow-hidden">
                {[1, 2, 3].map((index) => (
                  <img
                    key={index}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                    src={`https://randomuser.me/api/portraits/men/${index}.jpg`}
                    alt=""
                  />
                ))}
              </div>
              <span className="ml-3 text-sm font-semibold text-gray-700">Join our team of successful travel agents</span>
            </div>
          </div>
          <div className="lg:w-1/2 mt-10 lg:mt-0">
            <div className="relative">
              <img
                src="/Portugal.jpeg"
                alt="Norwegian Prima Inaugural"
                className="rounded-lg shadow-xl w-full h-auto"
              />
              <div className="absolute -top-4 -left-4 w-24 h-24 bg-blue-100 rounded-full flex items-center justify-center">
                <svg className="w-12 h-12 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
